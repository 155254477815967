<template>
  <v-container v-if="tooltip" class="text-uppercase pa-0 ma-0">
    <v-row no-gutters>
      <v-col>
        <span class="font-weight-bold text-h6">{{ event.convoglio.nome }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-3">
      <v-col>
        <span>ETA:</span>
        <span class="font-weight-bold">{{ event.convoglio.eta | formatDateTime }}</span>
      </v-col>
      <v-col>
        <span>ATA:</span>
        <span class="font-weight-bold">{{ event.convoglio.ata | formatDateTime }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>ETD:</span>
        <span class="font-weight-bold">{{ event.convoglio.etd | formatDateTime }}</span>
      </v-col>
      <v-col>
        <span>ATD:</span>
        <span class="font-weight-bold">{{ event.convoglio.atd | formatDateTime }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>t. lav.:</span>
        <span class="font-weight-bold">{{ event.convoglio.tempoLavorazione }}</span>
      </v-col>
      <v-col> 
        <span>t. occ.:</span>
        <span class="font-weight-bold">{{ event.inizio | formatDateTimeNoYear }} - {{ event.fine | formatDateTimeNoYear }}</span>        
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>lunghezza: </span>
        <span class="font-weight-bold">{{ event.convoglio.lunghezza}}</span>
      </v-col>
      <v-col> 
        <span>peso: </span>
        <span class="font-weight-bold">{{ event.convoglio.pesoTotale }} </span>        
      </v-col>
    </v-row>

    <v-row no-gutters class="pt-3">
      <v-col> 
        <span>viaggi in (prov.):</span>
      </v-col>
      <v-col>
        <div v-for="viaggio in event.viaggiArrivo" :key="viaggio.idViaggio">
          <span class="font-weight-bold">{{ viaggio.nomeViaggio }} - {{ viaggio.destOPartenza }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col> 
        <span>viaggi out (dest.):</span>
      </v-col>
      <v-col>
        <div v-for="viaggio in event.viaggiPartenza" :key="viaggio.idViaggio">
          <span class="font-weight-bold">{{ viaggio.nomeViaggio }} - {{ viaggio.destOPartenza }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else class="text-uppercase pa-0 ma-0">
    <v-row no-gutters>
      <v-col>
        <span class="font-weight-bold text-h6">{{ event.status }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-3">
      <v-col cols="3">
        <span>ETA:</span>
        <span class="font-weight-bold">{{ event.convoglio.eta | formatDateTime }}</span>
      </v-col>
      <v-col cols="3">
        <span>ATA:</span>
        <span class="font-weight-bold">{{ event.convoglio.ata | formatDateTime }}</span>
      </v-col>
      <v-col cols="3">
        <span>ETD:</span>
        <span class="font-weight-bold">{{ event.convoglio.etd | formatDateTime }}</span>
      </v-col>
      <v-col cols="3">
        <span>ATD:</span>
        <span class="font-weight-bold">{{ event.convoglio.atd | formatDateTime }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>t. lav.:</span>
        <span class="font-weight-bold">{{ event.convoglio.tempoLavorazione }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-3">
      <v-col cols="2"> 
        <span>viaggi in (prov.):</span>
      </v-col>
      <v-col cols="4">
        <div v-for="viaggio in event.viaggiArrivo" :key="viaggio.idViaggio">
          <span class="font-weight-bold">{{ viaggio.nomeViaggio }} - {{ viaggio.destOPartenza }}</span>
        </div>
      </v-col>
      <v-col cols="2"> 
        <span>viaggi out (dest.):</span>
      </v-col>
      <v-col cols="4">
        <div v-for="viaggio in event.viaggiPartenza" :key="viaggio.idViaggio">
          <span class="font-weight-bold">{{ viaggio.nomeViaggio }} - {{ viaggio.destOPartenza }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import railsMixins from "../rails/mixins/railsMixins";

export default {
  name: "ConvoyInfoPanelTrackPlanning",
  mixins: [railsMixins],
  data() {
    return {};
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  mounted: function () {
    this.getPeso();
  
  }, 
  beforeDestroy: function () {
  }, 
  methods: {
    async getPeso(){
      let peso=this.event.convoglio.pesoTotale;
      if(peso) return peso;
      const id=this.event.convoglio.id;
      peso= await this.$api.railData.getConvoyWeight(id);
      this.event.convoglio.pesoTotale=peso;
    }
  }
};
</script>
