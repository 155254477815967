<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text class="rails-panel">
      <!-- <v-row v-for="o in tracksOccupancies" :key="o.id">
        <v-col cols="12">
          I: {{ o.inizio | formatDateTime }} <br />
          F: {{ o.fine | formatDateTime }}
        </v-col>
      </v-row> -->
      <v-sheet height="100%">
        <!-- <v-row>
          <v-col cols="12"> value: {{ value }} </v-col>
        </v-row>
        <v-row v-for="event in todayEvents" :key="event.id">
          <v-col cols="12">
            S: {{ event.start }} <br />
            E: {{ event.end }}
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" style="width: 100%; overflow: hidden">
            <v-calendar
              ref="todayCalendar"
              class="rm-scroll-cal mr-0 pr-0"
              color="primary"
              type="day"
              :events="todayEvents"
              :event-color="getEventColor"
              locale="it"
              :event-overlap-mode="'column'"
              :event-overlap-threshold="30"
              :hide-header="true"
              v-model="value"
              :start="value"
              @click:event="showEvent"
              :style="shiftsEnabled === 'true' ? (isPlanning ? 'width:95%;' : !planningEnabled ? 'width:85%;' : 'width:75%;') + 'float: left;' : null"
              :interval-format="intervalFormat"
              :first-interval="calendarStartTime"
              :interval-count="24 - calendarStartTime"
            >
              <template v-slot:day-body="{ date, week }">
                <div v-if="date === nowDate && todayNowY !== '0px'" class="v-current-time" :class="{ first: date === week[0].date }" :style="{ top: todayNowY }"></div>
              </template>

              <template v-slot:event="{ event }">
                <v-tooltip right color="#dfe0e1">
                  <template v-slot:activator="{ on }">
                    <div
                      :class="`conv-${event.convoglio.id} text-caption`"
                      style="overflow: hidden; height: 100%"
                      v-on="on"
                      v-on:click.prevent="showEvent(event)"
                      v-on:mouseenter="addClass('.conv-' + event.convoglio.id, 'convSel')"
                      v-on:mouseleave="removeClass('.conv-' + event.convoglio.id, 'convSel')"
                    >
                      <h4>{{ eventViaggiLabel(event.viaggiArrivo) }}</h4>
                      <span>
                        {{ event.inizio | formatDateTimeNoYear }}
                      </span>
                      <h4>{{ eventViaggiLabel(event.viaggiPartenza) }}</h4>
                      <span>
                        {{ event.fine | formatDateTimeNoYear }}
                      </span>
                      <br />
                      <span>Carri: </span>
                      <span>
                        {{ event.convoglio.numeroCarri }}
                      </span>
                      <br />
                      <span>
                        {{ eventViaggiCodeLabel(event.viaggiArrivo) }}
                      </span>
                      <br />
                      <span>
                        {{ eventViaggiCodeLabel(event.viaggiPartenza) }}
                      </span>

                      <br />
                      <span v-if="event.binarioId"> pos. {{ event.posizioneInizio }} - {{ event.posizioneFine }} </span>
                    </div>
                  </template>
                  <div style="width: 450px">
                    <ConvoyInfoPanelTrackPlanning :event="event" tooltip />
                  </div>
                </v-tooltip>
              </template>
            </v-calendar>

            <v-calendar
              ref="todayCalendarTurni"
              class="rm-scroll-cal-turni ml-0 pl-0"
              type="day"
              :events="shifts"
              locale="it"
              event-color="grey lighten-1"
              :hide-header="true"
              v-model="value"
              v-if="shiftsEnabled === 'true'"
              :first-interval="calendarStartTime"
              :interval-count="24 - calendarStartTime"
            >
              <template v-slot:event="{ event }">
                <div class="rotate text-caption text-uppercase">
                  <div>{{ event.name }}</div>
                </div>
              </template>
            </v-calendar>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12">
            value D-A: {{ valueDayAfter }}
          </v-col>
        </v-row>
        <v-row v-for="event in tomorrowEvents" :key="event.id">
          <v-col cols="12">
            S: {{ event.start }} <br/> 
            E: {{ event.end }}
          </v-col>
        </v-row> -->

        <v-row v-if="calendarStartTime != 0">
          <v-col cols="12" style="width: 100%; overflow: hidden">
            <v-calendar
              ref="tomorrowCalendar"
              class="rm-scroll-cal mr-0 pr-0"
              color="primary"
              type="day"
              :events="tomorrowEvents"
              :event-color="getEventColor"
              locale="it"
              :event-overlap-mode="'column'"
              :event-overlap-threshold="30"
              :hide-header="true"
              v-model="valueDayAfter"
              :start="valueDayAfter"
              @click:event="showEvent"
              :style="shiftsEnabled === 'true' ? (isPlanning ? 'width:95%;' : !planningEnabled ? 'width:85%;' : 'width:75%;') + 'float: left;' : null"
              :interval-format="intervalFormat"
              :interval-count="calendarStartTime"
            >
              <template v-slot:day-body="{ date, week }">
                <div v-if="date === nowDate && tomorrowNowY !== '0px'" class="v-current-time" :class="{ first: date === week[0].date }" :style="{ top: tomorrowNowY }"></div>
              </template>

              <template v-slot:event="{ event }">
                <v-tooltip right color="#dfe0e1">
                  <template v-slot:activator="{ on }">
                    <div
                      :class="`conv-${event.convoglio.id} text-caption`"
                      style="overflow: hidden; height: 100%"
                      v-on="on"
                      v-on:click.prevent="showEvent(event)"
                      v-on:mouseenter="addClass('.conv-' + event.convoglio.id, 'convSel')"
                      v-on:mouseleave="removeClass('.conv-' + event.convoglio.id, 'convSel')"
                    >
                      <h4>{{ eventViaggiLabel(event.viaggiArrivo) }}</h4>
                      <span>
                        {{ event.inizio | formatDateTimeNoYear }}
                      </span>
                      <h4>{{ eventViaggiLabel(event.viaggiPartenza) }}</h4>
                      <span>
                        {{ event.fine | formatDateTimeNoYear }}
                      </span>
                      <br /><br />
                      <span v-if="event.binarioId"> pos. {{ event.posizioneInizio }} - {{ event.posizioneFine }} </span>
                    </div>
                  </template>
                  <div style="width: 450px">
                    <ConvoyInfoPanelTrackPlanning :event="event" tooltip />
                  </div>
                </v-tooltip>
              </template>
            </v-calendar>

            <v-calendar
              ref="tomorrowCalendarTurni"
              class="rm-scroll-cal-turni ml-0 pl-0"
              type="day"
              :events="shifts"
              locale="it"
              event-color="grey lighten-1"
              :hide-header="true"
              v-model="value"
              v-if="shiftsEnabled === 'true'"
              :interval-count="calendarStartTime"
            >
              <template v-slot:event="{ event }">
                <div class="rotate text-caption text-uppercase">
                  <div>{{ event.name }}</div>
                </div>
              </template>
            </v-calendar>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card-text>
    <ConvoyEditDialog :editMode="editMode" :dialog="dialog" :selectedEvent="selectedEvent" :parcoBinariCodice="parkCode" @close="(dialogOpenValue) => (dialog = dialogOpenValue)" />
  </v-card>
</template>

<script>
import moment from "moment";
import ToastMixin from "../../mixins/ToastMixin";
import ConvoyEditDialog from "./ConvoyEditDialog";
import ConvoyInfoPanelTrackPlanning from "./ConvoyInfoPanelTrackPlanning";
import RailsMixins from "../rails/mixins/railsMixins";

export default {
  name: "railsPanel",
  components: {
    ConvoyEditDialog,
    ConvoyInfoPanelTrackPlanning,
  },
  data() {
    return {
      todayEvents: [],
      tomorrowEvents: [],
      dialog: false,
      selectedEvent: null,
      valid: true,
      numericRules: [(v) => v === 0 || !!v || "campo obbligatorio", (v) => (!isNaN(v) && +v >= 0) || "valore numerico non valido"],
      requiredRules: [(v) => !!v || "campo obbligatorio"],
      shiftsEnabled: process.env.VUE_APP_SHIFTS_ENABLED || "false",
      valueDayAfter: moment().add(1, "day").format("YYYY-MM-DD"),
      calendarStartTime: process.env.VUE_APP_CALENDAR_START_TIME || 0,
      ready: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "Binario",
    },
    tracksOccupancies: {
      type: Array,
      default: () => [],
    },
    parkCode: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: () => moment().format("YYYY-MM-DD"),
    },
    shifts: {
      type: Array,
      default: () => [],
    },
    isPlanning: {
      type: Boolean,
      default: false,
    },
    planningEnabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [ToastMixin, RailsMixins],
  created() {
    this.$eventBus.$on("close", (dialog) => {
      this.dialog = dialog;
    });
  },
  mounted() {
    this.ready = true;
    this.todayScrollToTime();
    this.todayUpdateTime();

    this.tomorrowScrollToTime();
    this.tomorrowUpdateTime();

    if (this.value) {
      this.valueDayAfter = moment(this.value).add(1, "day").format("YYYY-MM-DD");
    }
    this.eventDataAdapter(this.tracksOccupancies);
  },
  computed: {
    todayCal() {
      return this.ready ? this.$refs.todayCalendar : null;
    },
    todayNowY() {
      return this.todayCal ? this.todayCal.timeToY(this.todayCal.times.now) + "px" : "-10px";
    },
    tomorrowCal() {
      return this.ready ? this.$refs.tomorrowCalendar : null;
    },
    tomorrowNowY() {
      return this.tomorrowCal ? this.tomorrowCal.timeToY(this.tomorrowCal.times.now) + "px" : "-10px";
    },
    nowDate() {
      return moment().format("YYYY-MM-DD");
    },
    nowDateTime() {
      return moment().format("YYYY-MM-DD HH:mm");
    },
  },
  watch: {
    tracksOccupancies: {
      handler: function (newVal) {
        this.eventDataAdapter(newVal);
      },
      deep: true,
    },
    value: {
      handler: function (newVal) {
        this.valueDayAfter = moment(newVal).add(1, "day").format("YYYY-MM-DD");
        this.eventDataAdapter(this.tracksOccupancies);
      },
      deep: true,
    },
  },
  methods: {
    eventViaggiLabel(viaggi) {
      let viaggiLabel = "";
      if (viaggi && viaggi.length > 0) {
        viaggi.forEach((viaggio) => {
          viaggiLabel += viaggio.nomeViaggio + " ";
        });
      }
      return viaggiLabel;
    },
    eventViaggiCodeLabel(viaggi) {
      let viaggiLabel = "";
      if (viaggi && viaggi.length > 0) {
        viaggi.forEach((viaggio) => {
          if (viaggio.locode != null) {
            viaggiLabel += viaggio.locode + " ";
          }
        });
      }
      return viaggiLabel;
    },
    eventStartEndLabel(event) {
      let startEvent = this.todayEvents.find((a) => a.name === event.name);
      let endEvent = this.tomorrowEvents.find((a) => a.name === event.name);
      return this.$utilities.dates.getTimeFromDate(startEvent.start) + " - " + this.$utilities.dates.getTimeFromDate(endEvent ? endEvent.end : startEvent.end);
    },
    onChangeDate() {
      //this.$emit('input', this.value);
    },
    intervalFormat(interval) {
      return interval.time;
    },
    eventDataAdapter(tracksOccupancies) {
      // if (tracksOccupancies && tracksOccupancies.length > 0) {
      //   console.log("eventDataAdapter tracksOccupancies", tracksOccupancies.length, tracksOccupancies, JSON.stringify(tracksOccupancies));
      // }
      if (this.calendarStartTime != 0) {
        let to = moment(this.value).add(24, "hour");
        this.todayEvents = this._.map(tracksOccupancies, (occ) => {
          let ev = JSON.parse(JSON.stringify(occ));
          ev.name = occ.convoglio.nome ? occ.convoglio.nome : `Convoglio ${occ.convoglio.id}`;
          ev.start = moment(occ.inizio).format("YYYY-MM-DD HH:mm");
          ev.end = moment(occ.fine) < to ? moment(occ.fine).format("YYYY-MM-DD HH:mm") : moment(this.value).format("YYYY-MM-DD 24:00");

          const status = occ.convoglio.stato ? occ.convoglio.stato.toLowerCase() : "creato";
          ev.color = this.$config.rails.convoyStates[status].color;
          ev.status = this.$config.rails.convoyStates[status].status;

          // console.log("++++++++++++++++++++ to", to.format("YYYY-MM-DD HH:mm"));
          // console.log("++++++++++++++++ ev", ev.start, ev.end, ev);
          return ev;
        });

        // if (this.todayEvents.length > 0) {
        //   console.log("---------- todayEvents", this.todayEvents.length, this.todayEvents);
        // }

        let fromDayAfter = moment(this.valueDayAfter);
        let toDayAfter = to.add(this.calendarStartTime, "hour");
        this.tomorrowEvents = this._.map(tracksOccupancies, (occ) => {
          let ev = JSON.parse(JSON.stringify(occ));
          ev.name = occ.convoglio.nome ? occ.convoglio.nome : `Convoglio ${occ.convoglio.id}`;
          ev.start = moment(occ.inizio) < fromDayAfter ? moment(fromDayAfter).format("YYYY-MM-DD 00:00") : moment(occ.inizio).format("YYYY-MM-DD HH:mm");
          ev.end = moment(occ.fine) < toDayAfter ? moment(occ.fine).format("YYYY-MM-DD HH:mm") : moment(toDayAfter).format("YYYY-MM-DD HH:mm");

          const status = occ.convoglio.stato ? occ.convoglio.stato.toLowerCase() : "creato";
          ev.color = this.$config.rails.convoyStates[status].color;
          ev.status = this.$config.rails.convoyStates[status].status;

          // console.log("++++++++++++++++++++ fromDayAfter", fromDayAfter.format("YYYY-MM-DD HH:mm"));
          // console.log("++++++++++++++++ ev", ev.start, ev.end, ev);

          return ev;
        });

        // if (this.tomorrowEvents.length > 0) {
        //   console.log("------------ tomorrowEvents", this.tomorrowEvents.length, this.tomorrowEvents);
        // }
      } else {
        this.todayEvents = this._.map(tracksOccupancies, (occ) => {
          let ev = JSON.parse(JSON.stringify(occ));
          ev.name = occ.convoglio.nome ? occ.convoglio.nome : `Convoglio ${occ.convoglio.id}`;
          ev.start = moment(occ.inizio).format("YYYY-MM-DD HH:mm");
          ev.end = moment(occ.fine).format("YYYY-MM-DD HH:mm");

          const status = occ.convoglio.stato ? occ.convoglio.stato.toLowerCase() : "creato";
          ev.color = this.$config.rails.convoyStates[status].color;
          ev.status = this.$config.rails.convoyStates[status].status;

          return ev;
        });
      }
    },
    getEventColor(event) {
      return event.color;
    },
    showEvent({ event }) {
      console.log("showEvent", event);
      if (event) {
        this.selectedEvent = event;
        this.dialog = true;
      }
    },
    getTodayCurrentTime() {
      return this.todayCal ? this.todayCal.times.now.hour * 60 + this.todayCal.times.now.minute : 0;
    },
    todayScrollToTime() {
      const time = this.getTodayCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.todayCal?.scrollToTime(first);
    },
    todayUpdateTime() {
      setInterval(() => this.todayCal.updateTimes(), 60 * 1000);
    },
    getTomorrowCurrentTime() {
      return this.tomorrowCal ? this.tomorrowCal.times.now.hour * 60 + this.tomorrowCal.times.now.minute : 0;
    },
    tomorrowScrollToTime() {
      const time = this.getTomorrowCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.tomorrowCal?.scrollToTime(first);
    },
    tomorrowUpdateTime() {
      setInterval(() => (this.tomorrowCal && this.tomorrowCal.updateTimes()), 3 * 1000);
    },

    addClass(selector, newClass) {
      var elems = document.querySelectorAll(selector);
      for (let i = 0; i < elems.length; i++) {
        elems[i].classList.add(newClass);
      }
    },
    removeClass(selector, newClass) {
      var elems = document.querySelectorAll(selector);
      for (let i = 0; i < elems.length; i++) {
        elems[i].classList.remove(newClass);
      }
    },
  },
};
</script>

<style>
.rm-scroll-cal .v-calendar-daily__scroll-area {
  overflow-y: auto !important;
}
.rails-panel .v-calendar-daily__intervals-body {
  width: 0px !important;
}
.rails-panel .v-calendar-daily__interval-text {
  top: -16px !important;
}
.rails-panel .v-calendar-daily__interval {
  padding-right: 0px !important;
}
.rails-panel .v-calendar-daily__interval-text {
  font-size: 0.8em;
  width: 35px;
}

.rm-scroll-cal-turni {
  overflow: hidden !important;
}
.rm-scroll-cal-turni .v-calendar-daily__body {
  margin-right: 0 !important;
  overflow: hidden !important;
}
.rm-scroll-cal-turni .v-calendar-daily__pane {
  margin: 0% !important;
  overflow: hidden !important;
}
.rm-scroll-cal-turni .v-calendar-daily__scroll-area {
  overflow: hidden !important;
}
.rm-scroll-cal-turni .v-calendar-daily__interval-text {
  color: transparent !important;
  font-size: 0em;
}

.rm-scroll-cal-turni .v-event-timed-container {
  margin: 0% !important;
}

.rm-scroll-cal-turni .v-event-timed {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.rm-scroll-cal-turni.theme--light.v-calendar-daily {
  border: 0px !important;
}

.rm-scroll-cal-turni .v-calendar-daily__day {
  border: 0px !important;
}

.rm-scroll-cal-turni .v-calendar-daily__day-interval {
  border: 0px !important;
}

.rm-scroll-cal-turni .rotate {
  width: 100%;
  display: inline-block;
  position: relative;
}

.rm-scroll-cal-turni .rotate div {
  position: absolute;
  bottom: -20px;
  left: 0px;
  transform: translate(-100%, 0) rotate(270deg);
  -ms-transform: translate(-100%, 0) rotate(270deg);
  -webkit-transform: translate(-100%, 0) rotate(270deg);
  transform-origin: top right;
}

.v-current-time {
  height: 2px;
  background-color: #c62828;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

.convSel {
  border: 4px solid #e57373;
  border-radius: 4px;
}
</style>
