<template>
  <v-dialog v-model="dialog" max-width="1050" @click:outside="closeDetailsDialog">
    <v-form ref="form" v-model="valid">
      <v-card v-if="dialog">
        <v-card-title class="header headline">
          Convoglio {{ selectedEvent.name }}
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="binarioIdPrec !== null"
            @click="$router.push({ path: `/rails/ConvoyVisualization/${convIntItem.id}` })">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <ConvoyInfoPanelTrackPlanning :event="selectedEvent" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="selectedEvent.manovre.length > 0">
              <v-col cols="12">
                <v-card>
                  <v-card-title class="panelheader py-1">Manovre</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-simple-table fixed-header dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-if="editMode" class="text-left">{{ tLabel("") }}</th>
                                <th class="text-left">{{ tLabel("Inizio") }}</th>
                                <th class="text-left">{{ tLabel("Fine") }}</th>
                                <th class="text-left">{{ tLabel("Parco") }}</th>
                                <th class="text-left">{{ tLabel("Binario") }}</th>
                                <th class="text-left">{{ tLabel("Pos.Inizio") }}</th>
                                <th class="text-left">{{ tLabel("Pos.Fine") }}</th>
                                <th class="text-left">{{ tLabel("Stato") }}</th>
                                <th class="text-left">{{ tLabel("Manovra") }}</th>
                              </tr>
                            </thead>
                            <tbody v-if="listOccupazioneWithUndoInfo.length > 0">
                              <tr v-for="occ in listOccupazioneWithUndoInfo" :key="occ.id">
                                <td v-if="editMode">
                                  <v-icon v-if="occ.canUndo" @click="deleteMove(occ)">mdi-delete</v-icon>
                                </td>
                                <td>{{ occ.inizio | formatDateTimeFromDate }}</td>
                                <td>{{ occ.fine | formatDateTimeFromDate }}</td>
                                <td>{{ occ.parcoBinari }}</td>
                                <td>{{ occ.binario }}</td>
                                <td>{{ occ.posizioneInizio }}</td>
                                <td>{{ occ.posizioneFine }}</td>
                                <td>{{ occ.stato }}</td>
                                <td>{{ occ.tipoManovra }}</td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="4">{{ tLabel("Nessuna manovra.") }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="6" md="3">
                <v-select v-model="parcoBinari" :readonly="!editMode" :items="parchiBinari" label="Parco binari"
                  item-text="codice" return-object @change="onChangeParcoBinari" />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col :cols="binarioIdPrec !== null ? 4 : 6">
                <v-select v-model="convIntItem.binarioId" :readonly="!editMode" :items="listBinari" label="Binario"
                  required :rules="[validationRules.required]" @change="binarioId = convIntItem.binarioId" />
              </v-col>
              <v-col :cols="binarioIdPrec !== null ? 4 : 6">
                <v-text-field :readonly="!editMode" label="Posizione binario (m)" type="number"
                  v-model="convIntItem.posizioneBinario" required
                  :rules="[validationRules.requiredOrZero, validationRules.greaterThanOrEqualToZero]"
                  @input="posizioneBinario = convIntItem.posizioneBinario" />
              </v-col>
              <v-col :cols="4" v-if="binarioIdPrec !== null">
                <v-datetime-picker label="Data / ora" v-model="convIntItem.dataOperazione" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-alert type="error" v-if="validationMessage">
                  {{ validationMessage }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeDetailsDialog"> Chiudi </v-btn>
          <v-btn v-if="editMode" color="success" @click="updateConvoy(true)" :disabled="shuntingDisabled">
            <v-icon left>mdi-swap-horizontal</v-icon>
            Manovra
          </v-btn>
          <v-btn v-if="editMode" color="success" @click="updateConvoy(false)" :disabled="assignDisabled">
            <v-icon left v-if="binarioIdPrec !== null">mdi-clipboard-check-outline</v-icon>
            {{ binarioIdPrec === null ? "Salva" : "Assegna" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import FormatMixins from "../../mixins/FormatsMixins";
import ToastMixin from "../../mixins/ToastMixin";
import ConvoyInfoPanelTrackPlanning from "../../components/misc/ConvoyInfoPanelTrackPlanning";

export default {
  name: "convoyEditDialog",
  components: {
    ConvoyInfoPanelTrackPlanning,
  },
  data() {
    return {
      valid: true,
      convIntItem: {},
      validationMessage: null,
      parchiBinari: [],
      parcoBinari: null,
      binari: [],
      binarioIdPrec: null,
      binarioId: null,
      posizioneBinarioPrec: null,
      posizioneBinario: null,
      listOccupazioneHeaders: [
        { text: "", value: "actions", width: "10px" },
        { text: "Inizio", value: "inizio", formatValue: "formatDateTimeFromDate" },
        { text: "Fine", value: "fine", formatValue: "formatDateTimeFromDate" },
        { text: "Parco", value: "parcoBinari" },
        { text: "Binario", value: "binario" },
        { text: "Pos.Inizio", value: "posizioneInizio" },
        { text: "Pos.Fine", value: "posizioneFine" },
        { text: "Stato", value: "stato" },
        { text: "Manovra", value: "tipoManovra" },
      ],
    };
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    selectedEvent: {
      type: Object,
      default: () => { },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    parcoBinariCodice: {
      type: String,
      required: true
    },
  },
  mixins: [ToastMixin, FormatMixins],
  watch: {
    binari: function (newVal, oldVal) {
      if (!newVal) {
        this.binari = oldVal;
      }
    },

    dialog: async function (value) {
      this.validationMessage = null;
      if (value) {
        this.binarioIdPrec = null
        const res = await this.$api.railData.getParchiBinari();
        this.parchiBinari = res.data;
        // console.log("---------- parchiBinari", this.parchiBinari);

        console.log("convIntItem from", this.selectedEvent);
        this.convIntItem = {};
        this.convIntItem["id"] = this.selectedEvent.convoglio.id;

        if (this.selectedEvent.manovre && this.selectedEvent.manovre.length > 0) {
          this.parcoBinari = this.parchiBinari.filter((p) => p.id === this.selectedEvent.manovre[this.selectedEvent.manovre.length - 1].parcoBinariId)[0];
          await this.onChangeParcoBinari();
          this.binarioIdPrec = this.selectedEvent.convoglio.binarioId;
          this.posizioneBinarioPrec = this.selectedEvent.convoglio.posizioneInizio;
        } else {
          if (this.parcoBinariId) {
            this.parcoBinari = this.parchiBinari[0];
          } else {
            this.parcoBinari = this.parchiBinari.filter((p) => p.codice === this.parcoBinariCodice)[0];
          }
          await this.onChangeParcoBinari();
        }

        this.binarioId = this.convIntItem["binarioId"];
        this.posizioneBinario = this.convIntItem["posizioneBinario"];
        this.convIntItem["dataOperazione"] = new Date();

        console.log("convIntItem", this.convIntItem);
      }
    },
  },
  computed: {
    listBinari() {
      if (this.binari) {
        return this.binari.map((x) => {
          return { value: x.id, text: x.descrizione };
        });
      }
      return [];
    },
    listOccupazioneWithUndoInfo() {
      if (this.selectedEvent.manovre.length > 0) {
        const last = this.selectedEvent.manovre[this.selectedEvent.manovre.length - 1];
        if (last.tipoManovra) last.canUndo = true;
      }
      return this.selectedEvent.manovre;
    },
    shuntingDisabled() {
      return this.binarioIdPrec === null || (
        this.binarioIdPrec === this.binarioId && 
        this.posizioneBinarioPrec == this.posizioneBinario
      );   
    },
    assignDisabled() {
      const hasShunting = this.selectedEvent.manovre.some(m => m.tipoManovra == 'SHUNTING');
      return hasShunting || (
        this.binarioIdPrec !== null &&
        this.binarioIdPrec === this.binarioId &&
        this.posizioneBinarioPrec == this.posizioneBinario
      );
    }
  },
  methods: {
    async onChangeParcoBinari() {
      this.binari = await this.$api.railData.getBinariByParco(this.parcoBinari.id);

      if (this.selectedEvent.manovre && this.selectedEvent.manovre.length > 0 &&
        this.parcoBinari.id === this.selectedEvent.manovre[this.selectedEvent.manovre.length - 1].parcoBinariId) {
        this.binarioId = this.selectedEvent.manovre[this.selectedEvent.manovre.length - 1].binarioId;
        this.convIntItem["posizioneBinario"] = this.selectedEvent.manovre[this.selectedEvent.manovre.length - 1].posizioneInizio;
      } else {
        this.binarioId = this.binari[0].id;
        this.convIntItem["posizioneBinario"] = new Number(0);
      }
      this.convIntItem["binarioId"] = this.binarioId;
      // console.log("------------ binari", this.binari);
    },
    closeDetailsDialog() {
      // this.$refs.form.resetValidation();
      this.$emit("close", false);
    },
    async updateConvoy(move) {
      try {
        this.convIntItem.move = move;
        console.log("updateConvoy convIntItem", this.convIntItem);
        if (this.$refs.form.validate()) {
          await this.$api.trainData.updateConvoyPlanning(this.convIntItem);
          this.closeDetailsDialog();
          this.showSuccess("convoglio salvato correttamente");
          this.$eventBus.$emit("convoySaved");
        } else {
          this.showError("dati del convoglio non corretti");
        }
      } catch (e) {
        console.log("Errore updateConvoy", e);
        if (e.status == 400 && 'detailMessage' in e) {
          if (!e?.data?.blocker && 
              e.detailMessage.includes("Conflitto binario") && 
              confirm('ATTENZIONE!\n\n' + e.detailMessage + '\n\nSostituire l\'assegnazione?')) {
            try {
              this.convIntItem.force = true;
              await this.$api.trainData.updateConvoyPlanning(this.convIntItem);
              this.closeDetailsDialog();
              this.showSuccess("convoglio salvato correttamente");
              this.$eventBus.$emit("convoySaved");
            } catch (e1) {
              if (e.data.errors) this.validationMessage = e.data.errors[""];
              else this.validationMessage = "Aggiornamento fallito: " + e.detailMessage;
              this.showError(this.validationMessage);
            } finally {
              this.convIntItem.force = false;
            }
          } else {
            if (e.data.errors) this.validationMessage = e.data.errors[""];
            else this.validationMessage = "Aggiornamento fallito: " + e.detailMessage;
            this.showError(this.validationMessage);
          } 
        }
      }
    },
    async deleteMove(move) {
      const tipo = move.tipoManovra;
      const altroConvoglio = tipo == "UN" || tipo == "SP";
      let msg = "Confermi cancellazione manovra?";
      if (altroConvoglio) msg = "Attenzione. L'operazione coinvolge anche un altro convoglio\n\n " + msg;
      const answer = window.confirm(msg);
      if (!answer) return;
      console.log("Delete lastMove :" + move);
      try {
        await this.$api.trainData.undoLastMove(move.id);
        this.closeDetailsDialog();
        this.showSuccess("Manovra cancellata");
      } catch (e) {
        console.log("Errore annulla manovra:", e);
        this.showError(e.detailMessage || e);
      } finally {
        this.$eventBus.$emit("convoySaved");
      }
    },
  },
};
</script>

<style>
.rm-scroll-cal .v-calendar-daily__scroll-area {
  overflow-y: auto !important;
}

.v-time-picker-title {
  color: #000000 !important;
}

.v-date-picker-title__date {
  opacity: 0.5 !important;
}

.v-time-picker-title__time {
  opacity: 0.5 !important;
}

.v-picker__title__btn {
  opacity: 0.5 !important;
}
</style>
